.availabilityCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 16px rgba(110, 110, 110, 0.28);
    border-radius: 15px;
    padding: 30px 20px;
    margin-bottom: 20px;
  }

  .availabilityCard .availabilityFirstBox {
    display: flex;
    align-items: center;
  }

  .availabilityCard .availabilityFirstBox .availabilityCardHeading {
    padding-left: 20px;
  }

  .cardImage {
    width: 50px;
    height: 50px;
  }

  .availabilityCard .availabilityFirstBox .cardImage img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    object-position: center;
  }

  .availabilityCard h1 {
    font-size: 20px;
    margin: 0;
    line-height: normal;
    color: #171b2a;
    font-weight: 700;
    font-family: var(--ltn__body-font);
  }

  .availabilityCard p {
    margin: 0;
    border: 1px solid #e4e4e4;
    border-radius: 4px;
    color: #171b2a;
    font-size: 14px;
    padding: 1px 8px;
    font-weight: 500;
    margin-top: 7px;
  }

  .availabilityCard button {
    font-size: 16px;
    border: 1px solid #00c800;
    color: #171b2a;
    background-color: transparent;
    border-radius: 30px;
    width: 80px;
    margin: 20px 20px 0 0;
  }

  .availabilityCard button:hover {
    background-color: #00c800;
    color: #fff;
  }

  @media (max-width: 1199px) {
    .availabilityCard button {
        margin: 20px 0 0 0;
    }
  }

  @media (max-width: 767px) {
    .availabilityCard {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    
    .availabilityCard button {
        margin: 30px 0 0 0;
    }
  }

  @media (max-width: 575px) {
    .availabilityCard .availabilityFirstBox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .availabilityCard .availabilityFirstBox .availabilityCardHeading {
        padding: 20px 0 0 0;
    }
  }
  