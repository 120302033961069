.map-container {
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  position: absolute;
  top: 0;
  left: 0;
}

body, html {
  height: 100vh;
  margin: 0;
  padding: 0;
  /* overflow-x: hidden; */
  font-family: Arial, Helvetica, sans-serif;
}

#map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}



.sidebarforsearch {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  overflow-y: auto;
  top: 0;
  left: -360px;
  width: 360px;
  height: 100%;
  padding: 5px 7px;
  background-color: #fff;
  z-index: 999;
  transition: all 0.3s ease-in-out;
}

.sidebarforsearch.open {
  right: 0;
}

.sidebarforsearch #sideabar-logo {
  max-width: 30%;
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 5px;
  border: 2px solid black;
  border-radius: 15px 0 15px 0;
}

.sidebarforsearch h2 {
  margin-top: 0;
}

.sidebarforsearch p {
  margin-bottom: 0;
  width: 97%;
  font-weight: 600;
}

.isActive {
  left: 0;
  box-shadow: 0 5px 14px 0 rgba(38, 38, 55, 0.15);
}

/* Exit button style */
.exit-button {
  position: absolute;
  top: 10px;
  right: 370px;
  cursor: pointer;
  line-height: 27px;
  padding-left: 12px;
  padding-right: 12px;
  /* color: #00c800; */
  border: 0.5px solid #ffffff;
  background: #00c800;
  box-shadow: 0px 0px 40px black;
  transition: all 0.3s ease-in-out;
  z-index: 999;
  /* Adjust the style as needed */
}

/* Container for Close and Reset buttons */
.buttons-container {
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  gap: 10px; /* Adjust gap between buttons */
}

button.open-button {
 
  z-index: 999;
  background: #00c800;
  color: white;
  transition: all 0.3s ease-in-out;
}


button.open-button-reset {
  position: absolute;
  top: 10px;
  right: 430px;
  line-height: 27px;
  padding-left: 12px;
  padding-right: 12px;
  z-index: 1000;
  border-radius: 5px;
  box-shadow: 0px 0px 40px black;
  background: red;
  color: white;
  transition: all 0.3s ease-in-out;
}



.sidebar-search {
  width: 100%;
}
.sidebar-search input {
  width: 70%;
}

.sidebar-search button {
  background: #00c864;
  color: #fff;
  border: 2px solid #0e7746;
}

.sidebarforsearch ol {
  width: 100%;
}
.sidebarforsearch li:hover {
  padding-left: 8px;
  transition: all 0.3s ease-in-out;
}

.scrollable {
  padding: 20px 10px;
  width: 100%;
}

.scrollable .content-box {
  overflow: hidden;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.scrollable .content-box .featured-image-style {
  max-width: 100%;
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
}
.scrollable .content-box .content {
  padding: 15px;
}
.scrollable .content-box .content .description:hover {
  color: var(--ltn__secondary-color);
  cursor: pointer;
}

.scrollable .content-box .flaticon-pin {
  color: var(--ltn__secondary-color);
}
.scrollable .content-box .location {
  font-size: 14px;
}

.scrollable .content-box .product-info-bottom {
  border-top: 1px solid;
  border-color: var(--border-color-1);
  padding: 15px;
}
.scrollable .content-box .product-info-bottom .product-price {
  color: var(--ltn__secondary-color);
}

.gm-style-iw-t {
  cursor: pointer;
}

.bottom-bar {
  position: absolute; /* or 'fixed' depending on the use-case */
  bottom: 0; /* Aligns the element to the bottom of the container */
  left: 50%; /* Starts at the halfway point of the container */
  transform: translateX(-50%); /* Centers the element horizontally */
  width: auto; /* Full width */
  gap: 10px;
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background-color: #171b2a00; 
  /* box-shadow: 0 2px 4px rgba(0,0,0,0.1); */
}

/* Button styling */
.bottom-bar button {
  background-color: #00c800; /* Bootstrap primary color */
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
  overflow: hidden;
}

.bottom-bar button:hover {
  background-color: #000000; /* Darken button on hover */
}

.search-box-container {
  width: 80%; /* Or any specific width */
  margin: auto; /* Center the search box if desired */
}

/* Styling the top bar */
.top-bar {
  position: absolute;
  top: 0;
  left: 50%;
  width: auto; /* Full width */
  gap: 10px;
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #171b2a00;
  transform: translateX(-50%);
  /* box-shadow: 0 2px 4px rgba(0,0,0,0.1);  */
}

.top-bar input[type="text"] {
  border: 1px solid #0e0d0d;
}

.top-bar select {
  width: auto;
  padding: 3.5px;
  border: 1px solid #0e0d0d;
  background-color: white;
}

/* Button styling */
.top-bar button {
  background-color: #00c800; /* Bootstrap primary color */
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
  overflow: hidden;
}

.top-bar button:hover {
  color: rgb(0, 0, 0);
  background-color: #ffffff; /* Darken button on hover */
}


/* Ensuring focus states are clear for accessibility */
*:focus {
  outline: 2px solid #000000; /* Ensuring focus is visible */
}
 .map-search-box {
  width: 100%;
 }


/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .map-container {
    width: 100%;
    height: auto;
  }
  .map-container input,
  .map-container select,
  .map-container button {
    width: 100%;
    margin: 8px 0; /* Stack vertically with space */
  }
  .top-bar, .map-container > div {
    flex-direction: column;
    /* align-items: center; */
  }
}

/* Flexible Layouts Using Flexbox */
/* .map-container > div {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
} */

/* Touch-friendly UI */
/* .map-container button,
.map-container select,
.map-container input[type="text"] {
  min-height: 44px; 
  padding: 12px;
} */

/* Optimized Input Fields */
/* .map-container input[type="text"] {
  border: 1px solid #ccc;
  box-shadow: none; 
} */

/* Accessibility Enhancements */
.map-container a:focus,
.map-container button:focus,
.map-container input:focus {
  outline: 2px solid #00c800; /* High contrast for better visibility */
}
